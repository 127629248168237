ion-loading {

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background: transparent !important;
  }

  ion-spinner {
    &.spinner-circles {
      color: white !important;
    }
  }

  &.rs-loading-with-message {
    .loading-wrapper {
      flex-direction: column !important;
    }

    .loading-wrapper .loading-content {
      text-align: center;
      background: $color-iss-sand-2;
      color: $color-iss-black;
      padding: 1rem;
      margin-top: 1rem;
      border-radius: 0;
    }
  }
}
