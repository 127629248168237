/* Use new icons as Ionic native icons following the next guide:
 * https://golb.hplar.ch/2018/01/Custom-SVG-icons-in-Ionic.html
 */
ion-icon {
  &[name*='rs-'] {
    background: currentColor;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 2.8rem;
    line-height: 1;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    mask-size: contain;
    min-height: 1.8rem;
    width: 0.85em;
  }

  &[name*='rs-history'] {
    mask-image: url(../assets/images/icon-history.svg);
  }

  &[name*='rs-more'] {
    mask-image: url(../assets/images/icon-more.svg);
  }

  &[name*='rs-check'] {
    mask-image: url(../assets/images/icon-check.svg);
    font-size: 4rem;
  }

  &[name*='rs-history'] {
    mask-image: url(../assets/images/icon-history.svg);
    font-size: 4rem;
  }

  &[name*='rs-nfc_sm'] {
    mask-image: url(../assets/images/scan-nfc_sm.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-cycle'] {
    mask-image: url(../assets/images/iss-cycle.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-file'] {
    mask-image: url(../assets/images/iss-file.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-download'] {
    mask-image: url(../assets/images/iss-download.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-centre'] {
    mask-image: url(../assets/images/iss-centre.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-filter'] {
    mask-image: url(../assets/images/iss-filter.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-settings'] {
    mask-image: url(../assets/images/iss-settings.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-exit'] {
    mask-image: url(../assets/images/iss-exit.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-notification'] {
    mask-image: url(../assets/images/iss-notification.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-positive'] {
    mask-image: url(../assets/images/iss-positive.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-person'] {
    mask-image: url(../assets/images/iss-person.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-ellipsis-vertical'] {
    mask-image: url(../assets/images/iss-ellipsis-vertical.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-calendar'] {
    mask-image: url(../assets/images/iss-calendar.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-close'] {
    mask-image: url(../assets/images/iss-close.svg);
    font-size: 3rem;
  }

  &[name*='rs-iss-info'] {
    mask-image: url(../assets/images/iss-info.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-warning'] {
    mask-image: url(../assets/images/iss-warning.svg);
    font-size: 4rem;
  }

  &[name*='rs-iss-attach'] {
    mask-image: url(../assets/images/iss-attach.svg);
    font-size: 4rem;
  }
}
