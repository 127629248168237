.segment-ios {
  .segment-button.segment-activated {
    background-color: transparent;
  }

  .segment-button {
    border-radius: 0 !important;
    border-left: none;
    border-right: none;
    border-top: none;
  }

  .segment-button:hover:not(.segment-activated) {
    background-color: transparent;
  }
}
