ion-toast.hack-toast-tap-to-dismiss::part(button) {
  background: transparent;
  font-size: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

ion-toast {
  --border-radius: 0;
}
