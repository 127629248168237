:root {
  --ion-toolbar-background: transparent;
}

ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  font-size: $font-size-regular;

  ion-buttons {
    ion-button {
      ion-icon {
        font-size: 2.5rem;
        color: $lighter-gray;
      }
    }
  }
}

ion-title {
  font-size: $font-size-regular;
  //text-align: center;
}
