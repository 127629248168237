ion-item {
  .rs-item-label {
    margin: 10px 8px 10px 0;
  }

  ion-label {
    p {
      color: $font-color-secondary;
      font-size: $font-size-regular;
    }

    h2 {
      color: $font-color-primary;
    }
  }
}
