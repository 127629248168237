// MEDIA QUERIES BREAKPOINTS
// --------------------------------------------------
// Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// COLOR VARIABLES BY RIDEO SOFTWARE
// --------------------------------------------------
// Not recommended to use them directly in your css, use the context specific variables below
// Named colors makes it easier to use a defined color pallette through all the application.

$soft-black: #232328;
$dark-gray: #4a4a4a;
$light-gray: #9b9b9b;
$gray: #e8e8e8;
$lighter-gray: #f8f8f7;
$lighter-gray: #f8f8f7;
$white: #ffffff;
$green: #78BC62;
$lightning-yellow: #FFA800;
$red: #E91E63;

$brand-primary: #030039;
$brand-secondary: #e84754;
$col-success: #0ab39c;
$col-danger: #f06548;

$col-warning: #f7b84b;

$dark-blueish: #858585;
$blueish: #89a5b5;
$electric-blue: #0084FF;


// COLORS TO BE USED AS IONIC'S COLOR ATTRIBUTE
:root {
  --ion-color-brand: #{$brand-primary};
  --ion-color-brand-rgb: 56, 128, 255;
  --ion-color-brand-contrast: #ffffff;
  --ion-color-brand-contrast-rgb: 255, 255, 255;
  --ion-color-brand-shade: #{$brand-primary};
  --ion-color-brand-tint: #4c8dff;
}

.ion-color-brand {
  --ion-color-base: var(--ion-color-brand);
  --ion-color-base-rgb: var(--ion-color-brand-rgb);
  --ion-color-contrast: var(--ion-color-brand-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-shade);
  --ion-color-tint: var(--ion-color-brand-tint);
}

// CONTEXT/HIERARCHY VARIABLES
// --------------------------------------------------
// Use then in your css
// Context/hierarchy variables make it easier to respect the hierarchy of styles through all the application.
// In case you have to create one, review the prototype and use the more general case (for example, "list-icon" instead of "agenda-section-category-list-icon". If you need to specify in the future, you can always do it.

$shade-primary-lv0: #fff; // OPA Far backgrounds

$shade-primary-lv1: #19232d; // Secondary boxes backgrounds
$shade-primary-lv1-border: #313b45; // Elements: border for lv1

$shade-primary-lv2: $brand-primary; // Elements: shade for clickable big elements
$shade-primary-lv2-border: #d8d8d8; // OPA Elements: border for lv2

$shade-primary-lv3: #4e6481; // Elements: secondary boxes without links
$shade-primary-lv4: #606a7b; // Elements: link, icons, link texts
$shade-primary-lv5: #0ab39c; // Elements: buttons sm boxes
$shade-primary-content-boxes: #f3f3f9; // Backgrounds for primary content boxes
$shade-card-background: #fff; // Background for normal cards
$shade-not-planned-card-background: #F4EBE5; // Background for not-planned task cards
$shade-based-desaturated: #000; // based on brand color but desaturated (used in icons buttons)
$shade-input: #e9e9ef;
$shade-delicate-border: #f0f0f0;
$shade-secondary-text-lv1: $brand-secondary;



$color-iss-black: #000000;
$color-iss-dark-blue: #030037;
$color-iss-white: #ffffff;
$color-iss-persian-blue: #153AC7;
$color-iss-azure-blue: #0084FF;
$color-iss-grey-1: #858585;
$color-iss-grey-2: #C2C2C2;
$color-iss-grey-3: #E0E0E0;
$color-iss-sand-1: #DECFB9;
$color-iss-sand-2: #E8DDCD;
$color-iss-sand-3: #F4EBE5;

$color-extra-debug: #F7B84B;


$shade-emphasize: $color-iss-azure-blue;



$closed-shade-color: #BCBCBC;
$closed-font-color: white;

$disabled-shade-color: #efefef;
$disabled-font-color: #bcbcbc;
$disabled-border-color: #e3e3e3;

$danger-background-color: #FDE8E4;
$danger-border-color: #FFDFA9;
$danger-font-color: #B44C36;
$danger-font-color-on-doc-cards: white;
$danger-shade-color: #e84754;

$warning-border-color: $color-iss-sand-1;
$warning-font-color: $color-iss-black;
$warning-font-color-on-doc-cards: white;
$warning-shade-color: $color-iss-black;
$warning-background-color: $color-iss-sand-2;

$in-progress-shade-color: #F5A623;
$in-progress-font-color: white;

$btn-primary-shade: $electric-blue;
$btn-secondary-shade: $brand-secondary;
$box-shadow-regular: rgb(0 0 0 / 25%) 0px 0px 4px 0px;

$problem-color: $red;
$success-color: #5BC1A5;
$warning-color: $lightning-yellow;

// TYPOGRAPHY VARIABLES
// --------------------------------------------------
// Useful to give the app a coherence through all the screens

$font-size-base: 62.5%;
$font-size-extra-small: 1.3rem;
$font-size-small: 1.4rem;
$font-size-regular: 1.6rem; // 16px
$font-size-large: 1.875rem;
$font-size-extra-large: 2.2rem;
$font-size-h1: 3.2rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

$line-height-regular: 2rem;

$font-color-primary: #1a1a1a; // Primary text in the page
$font-color-secondary: $dark-blueish; // Secondary texts (complementing a primary text)
$font-color-tertiary: $blueish; // Tertiary texts, usually independent but below secondary in hierarchy
$font-color-links: $electric-blue;
$font-color-links-secondary: $dark-blueish;

$alt-text-color: $color-iss-grey-1;
$btn-as-link-text-color: $electric-blue;

// LAYOUT VARIABLES
// --------------------------------------------------
// Layout variables are useful to be able to easily change general app layout properties

$content-left-margin: 1rem;
$content-right-margin: 1rem;
