section {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;

  &.form {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
  }
}
