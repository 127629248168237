.alert--danger {
  ion-label {
    color: $danger-font-color !important;
    font-weight: $font-weight-medium;
  }

  ion-icon {
    color: $danger-shade-color !important;
  }
}

.alert--warning {
  ion-label {
    color: $warning-font-color;
    font-weight: $font-weight-medium;
  }

  ion-icon {
    color: $warning-shade-color !important;
  }
}
