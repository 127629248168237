@import 'src/theme-rs/variables';

ion-input,
.sc-ion-input-md-h,
.sc-ion-input-ios-h {
  --background: #{$white};
  --padding-end: 2rem;
  --padding-start: 2rem;
  --padding-top: 1.4rem;
  --padding-bottom: 1.4rem;
  padding-left: 1.6rem !important;
  margin-top: 1.6rem;
  border-radius: 0;
  border: 1px solid $shade-primary-lv2-border;
}

// Remove chrome autofill background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 2000s ease-in-out 0s;
}

.invalid {
  ion-input,
  input {
    border-color: $col-danger !important;
  }
}

.input-error{
  color: $col-danger;
  font-size: 1.4rem;
  margin-top: -1rem;
}

.input-info {
  padding-left: 3rem;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: $alt-text-color;
}

.error-message {
  padding-left: 3rem;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: $col-danger !important;

  span {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.input--disabled {
  --background: #{$disabled-shade-color};
  --color: #{$disabled-font-color};
}

ion-textarea {
  border-radius: 0;
}
