/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/*
 * Rideo Software methodology
 * ----------------------------------------------------------------------------
 * Check _how_to_organize_files.txt for more details.
 */

/* Core */
@import 'theme-rs/variables';

/* Other (Alphabetically!) */
@import 'theme-rs/alert';
@import 'theme-rs/base';
@import 'theme-rs/bootstrap';
@import 'theme-rs/buttons';
@import 'theme-rs/card';
@import 'theme-rs/content';
@import 'theme-rs/datetime';
@import 'theme-rs/header';
@import 'theme-rs/icons';
@import 'theme-rs/input';
@import 'theme-rs/item';
@import 'theme-rs/list';
@import 'theme-rs/loading';
@import 'theme-rs/modal';
@import 'theme-rs/radio';
@import 'theme-rs/searchbar';
@import 'theme-rs/section';
@import 'theme-rs/segment';
@import 'theme-rs/tabs';
@import 'theme-rs/toast';
@import 'theme-rs/toolbar';
