ion-tab-button {
  --padding-start: 2px;
  --padding-end: 2px;
  padding-top: 6px;

  ion-label {
    font-size: 1.5rem;
    font-weight: $font-weight-medium;
  }

  .button-native {
    padding-left: 0;
    padding-right: 0;
  }
}

ion-tab-bar {
  border-top: 1px solid #d8d8d8;
}

ion-tab-bar {
  ion-tab-button {
    color: #abb9e8;
  }
  ion-tab-button.tab-selected {
    color: white;
  }
}
