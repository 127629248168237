@import 'src/theme-rs/variables';

.searchbar-input-container {
  background: $shade-input;
  --box-shadow: none;
}

.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  --background: #{$shade-input};

  .searchbar-input {
    border: 1px solid $shade-primary-lv2-border;
    border-radius: 0 !important;
  }

  ion-icon {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.searchbar-input-container {
  height: 40px;

  .searchbar-input {
    height: 100%;
  }
}
