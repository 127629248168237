// RIDEO UTILS
// --------------------------------------------------
// This file has utils for all Rideo Software ionic apps, not theme related

[col-4-5] {
  flex: 0 0 37.5%;
  width: 37.5%;
  max-width: 37.5%;
}

.centered-axis-x {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.centered-axis-y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.centered-axis-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.force-hide {
  display: none;
}

.rs-spacer-xxs {
  height: 10px;
}
.rs-spacer-xs {
  height: 20px;
}

.rs-spacer-sm {
  height: 40px;
}

.rs-spacer-30 {
  height: 30px;
}

.rs-spacer-md {
  height: 50px;
}

.rs-spacer-lg {
  height: 80px;
}

.rs-spacer-xl {
  height: 120px;
}

.rs-spacer-xxl {
  height: 160px;
}

.rs-margin-top-xs {
  margin-top: 20px;
}
