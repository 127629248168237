// Used when overlaysWebView = true
/*
.plt-android ion-header ion-toolbar:first-child {
  padding-top: calc(35px + env(safe-area-inset-top));
}
*/

ion-title.wide-absolute-center {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 60px;
  left: 0;
  opacity: 1 !important;
}

ion-title.absolute-center {
  position: absolute;
  width: calc(100% - 140px);
  height: 100%;
  top: 0;
  margin: 0 70px;
  left: 0;
}

.toolbar-ios ion-title.absolute-center {
  padding-left: 0;
  padding-right: 0;
}
