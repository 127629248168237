@import 'src/theme-rs/variables';

.transparent-modal {
  //--background: rgba(44, 39, 45, 0.2);
  --background: #17232e;

  &::part(content) {
    //backdrop-filter: blur(12px);
  }
}

.modal-select {
  --ion-item-background: #{$shade-primary-content-boxes};
  border: 1px solid $shade-primary-lv2-border;
  border-radius: 8px;
  cursor: pointer;


  &--disabled {
    --ion-item-background: #{$disabled-shade-color};
    border-color: $disabled-border-color;
    color: $disabled-font-color;
    cursor: initial;

    span {
      color: $disabled-font-color;
    }

    ion-icon {
      color: $disabled-font-color;
    }
  }

  ion-item {
    --inner-padding-end: 0;
  }

  ion-icon {
    margin-left: 0;
  }
}

.modal-content {
  --ion-background-color: #{$shade-primary-content-boxes};

  .toolbar {
    padding: 2.6rem 1.6rem 1.6rem;
  }

  ion-item {
    --background: #fff;
    border: 1px solid $shade-primary-lv2-border;
    cursor: pointer;

    p {
      font-weight: $font-weight-semibold;
    }

    + ion-item {
      border-top-color: transparent;
    }
  }

  .option {

    &--disabled {
      cursor: initial;
      --background: #{$disabled-shade-color};
      background-color: $disabled-shade-color;
      color: $disabled-font-color;
      border-color: $disabled-border-color;
    }

    &__description {
      p {
        margin: 0;
        margin-top: 2px;
        font-weight: $font-weight-normal;
        font-size: 1.4rem;
      }
    }
  }
}

ion-modal.is-modal-on-top {
  z-index: 90000 !important;
}
