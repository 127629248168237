ion-card-title {
  color: $font-color-primary;
}

ion-card {
  ion-label.sc-ion-label-md-h {
    p {
      color: $font-color-secondary;
    }
  }

  .footer-md::before {
    display: none;
  }
}
