@font-face {
  font-family: 'NotoSans';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/NotoSans-Bold.ttf') format('truetype');
}

:root {
  --ion-font-family: 'NotoSans';
  --ion-text-color: #{$font-color-primary};
  --ion-color-success: #{$col-success};
  --ion-background-color: #{$shade-primary-lv0};
  --ion-tab-bar-background: #{$white};
}

html {
  font-size: $font-size-base;
}

body {
  font-size: $font-size-regular;
  font-weight: $font-weight-normal;
}

p {
  color: $font-color-primary;
  font-size: $font-size-regular;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  --ion-font-family: 'Poppins';
  font-weight: $font-weight-medium;
  font-size: 2.4rem;
  color: $font-color-primary;
}

h3 {
  --ion-font-family: 'Poppins';
  font-weight: $font-weight-medium;
  font-size: 1.8rem;
  color: $font-color-primary;
}

h4 {
  color: $font-color-primary;
  font-weight: $font-weight-normal;
  font-size: 1.4rem;
}

.title {
  color: $font-color-primary !important;
}

.small-title {
  font-size: $font-size-large !important;
}

.regular-text {
  font-size: $font-size-regular !important;
}

.alt-text {
  color: $font-color-secondary;
}

.text-emphasize {
  color: $shade-emphasize;
}

.text-bold {
  font-weight: $font-weight-semibold !important;
}

.text-weight-regular {
  font-weight: $font-weight-normal !important;
}

.text-small {
  font-size: $font-size-small !important;
}

.text-without-ellipsis {
  white-space: normal;
}

.mvp-notice {
  color: $danger-font-color;
  text-align: center;
}

.input-warning-text {
  color: $warning-font-color;
  font-size: $font-size-small;
  line-height: 1.8rem;
  margin-top: 0.8rem;
}

.all-uppercase {
  text-transform: uppercase;

  ::placeholder {
    text-transform: initial;
  }
}
