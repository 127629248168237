.btn {
  font-family: inherit;
  background-image: none;
  cursor: pointer;
  border-radius: 0;
  padding: 1.4rem 1.6rem;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  font-style: inherit;
  white-space: inherit;
  display: block;
  position: relative;
  outline: none;
  z-index: 0;
  box-sizing: border-box;
  appearance: none;
  font-weight: $font-weight-semibold;
  font-size: $font-size-regular;

  &:focus {
    //outline: 5px auto -webkit-focus-ring-color; // Removed since it was shown in an ugly way in the image add form
  }

  // Main buttons
  &--primary {
    background-color: $btn-primary-shade;
    color: white;
    border: 1px solid $btn-primary-shade;;

    &:hover {
      background-color: darken($btn-primary-shade, 15%);
    }
  }

  &--primary-outline {
    background-color: white;
    color: $btn-primary-shade;
    border: 1px solid $btn-primary-shade;;

    &:hover {
      //color: darken($btn-primary-shade, 15%);
    }
  }

  &--close {
    background-color: $success-color;
    color: white;
    //border: 1px solid $success-color;

    &:hover {
      //color: darken($success-color, 15%);
    }
  }

  &--close-outline {
    background-color: white;
    color: $success-color;
    border: 1px solid $success-color;

    &:hover {
      //color: darken($success-color, 15%);
    }
  }

  &--reject {
    background-color: $danger-shade-color;
    color: white;
    //border: 1px solid darken($danger-shade-color, 10%);

    &:hover {
      //background-color: darken($danger-shade-color, 5%);
    }
  }

  &--reject-outline {
    background-color: white;
    border: 1px solid $danger-shade-color;
    color: $danger-shade-color;

    &:hover {
      //color: darken($danger-shade-color, 15%);
    }
  }

  // NFC related
  &--secondary {
    background-color: $btn-secondary-shade;
    color: white;

    &:hover {
      //background-color: darken($btn-secondary-shade, 5%);
    }
  }

  // When in progress
  &--in-progress {
    background-color: $in-progress-shade-color;
    border: 1px solid $in-progress-shade-color;
    color: white;

    &:hover {
      //background-color: darken($in-progress-shade-color, 5%);
    }
  }

  // Used for reject
  &--danger {
    background-color: #f06548;
    color: white;

    &:hover {
      //background-color: darken(#f06548, 5%);
    }
  }

  &--outline {
    background-color: transparent;
    color: $btn-primary-shade;
    border: 2px solid $btn-primary-shade;

    &:hover {
      //color: darken($btn-primary-shade, 5%);
      //border-color: darken($btn-primary-shade, 5%);
      //background-color: transparent;
    }
  }

  &--block {
    display: block;
    width: 100%;
    clear: both;
    contain: content;
  }

  &-full {
    --padding-start: 0;
    --padding-end: 0;
    width: 100%;
    border-radius: 0;
    padding: 0;
  }

  &--with-only-icon {
    padding: 0 !important;
  }

  &:disabled {
    background-color: $disabled-shade-color;
    color: $disabled-font-color;
    border: 1px solid $disabled-border-color;
  }
}

ion-button {
  --background-color: #93e9be;
  --border-radius: 100px;
  --box-shadow: none;
  text-transform: none;
  font-size: $font-size-regular;
  font-weight: $font-weight-semibold;
  white-space: normal;

  span {
    // used to avoid word break inside the span
    display: contents;
    white-space: pre;
  }
}

button:focus {
  outline: none;
}
